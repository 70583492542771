<template>
    <ModalBox
        :is-active.sync="isModalActive"
        @submit="addIntegration">
        <template #header>
            <h5 class="title is-5">
                {{ $t(`admin.integrations.modal.header.newIntegration`) }}
            </h5>
        </template>
        <div>
            <div class="column is-narrow">
                <b-field>
                    <b-switch v-model="isLaunched">
                        {{ $t("admin.integrations.modal.body.status") }}
                    </b-switch>
                </b-field>
            </div>
            <div class="columns">
                <div class="column is-6">
                    <b-field :label="$t('admin.integrations.modal.body.offer')">
                        <FSelect
                            v-model="offer"
                            :get-data-vuex="getOffers"
                            :min-required-length="1"
                            :placeholder="$t(`admin.integrations.modal.body.offer`)"
                            field="name">
                            <template #text="option">
                                <OfferOutput :offer="option.option"></OfferOutput>
                            </template>
                            <template #selected-option="option">
                                <OfferOutput :offer="option.option"></OfferOutput>
                            </template>
                        </FSelect>
                    </b-field>
                </div>
                <div class="column is-6">
                    <b-field :label="$t(`admin.integrations.modal.body.advertiser`)">
                        <FSelect
                            v-model="advertiser"
                            :disabled="!offer"
                            :get-data-vuex="getAdvertisers"
                            :min-required-length="1"
                            :placeholder="$t(`admin.integrations.modal.body.advertiser`)"
                            :searchable="false"
                            :seed="offer"
                            field="login">
                            <template #text="option">
                                <UserOutput :user="option.option"></UserOutput>
                            </template>
                            <template #selected-option="option">
                                <UserOutput :user="option.option"></UserOutput>
                            </template>
                        </FSelect>
                    </b-field>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <b-field :label="$t(`admin.integrations.modal.body.CRM`)">
                        <FSelect
                            v-model="crm"
                            :get-data-vuex="getCrmList"
                            :placeholder="$t(`admin.integrations.modal.body.CRM`)"
                            field="name"
                            prop="alias">
                        </FSelect>
                    </b-field>
                </div>
            </div>
            
            <b-loading
                :active="isLoadingCrm"
                :is-full-page="false"></b-loading>
            
            <el-form
                :key="option.name"
                v-for="option in options"
                :model="{ value: option.value }">
                <FField
                    :label="option.name"
                    class="py-1">
                    <RequiredField
                        v-model="option.value"
                        :required="option.isRequired"
                        
                        prop="value">
                    </RequiredField>
                </FField>
            </el-form>
            
            <div
                v-if="!isCrmSelected"
                class="hero has-text-centered">
                <div class="hero-body">
                    <h2 class="is-size-5 is-bold has-text-weight-medium has-text-grey">
                        {{ $t("admin.integrations.modal.body.selectCrm") }}
                    </h2>
                </div>
            </div>
        </div>
        <template #footer>
            <div class="container">
                <div class="columns">
                    <div class="column">
                        <b-button
                            :disabled="!isAbleToCreate"
                            class="is-fullwidth"
                            native-type="submit"
                            type="is-info is-light">
                            {{ $t(`admin.integrations.modal.footer.save`) }}
                        </b-button>
                    </div>
                    <div class="column">
                        <b-button
                            class="is-fullwidth"
                            type="is-light-button"
                            @click.prevent="cancelIntegration">
                            {{ $t(`admin.integrations.modal.footer.cancel`) }}
                        </b-button>
                    </div>
                </div>
            </div>
        </template>
    </ModalBox>
</template>

<script>
  import ModalBox from "@/components/Common/ModalBox";
  import OfferOutput from "@/components/Common/OfferOutput";
  import FSelect from "@/components/Common/FSelect";
  import UserOutput from "@/components/Common/UserOutput";
  import { Modal } from "@core/mixins";
  import {
    CREATE_INTEGRATION,
    GET_CRM_LIST,
    GET_CRM_OPTIONS,
    GET_INITIAL_OFFERS,
    GET_INTEGRATIONS,
    GET_OFFER_ADVERTISERS_LIST,
    GET_OFFERS_LIST,
    SET_EMPTY
  } from "@core/store/action-constants";
  import {
    SET_ADVERTISER_ID,
    SET_CRM_ALIAS,
    SET_INTEGRATION_OPTIONS,
    SET_IS_LAUNCHED,
    SET_OFFER_ID,
    UPDATE_CRM_FILTERS,
    UPDATE_CRM_LIST_PAGINATION,
    UPDATE_INTEGRATION_FILTERS,
    UPDATE_INTEGRATION_OPTIONS,
    UPDATE_OFFER_ADVERTISERS_FILTERS,
    UPDATE_OFFER_ADVERTISERS_PAGINATION,
    UPDATE_OFFERS_FILTERS,
    UPDATE_OFFERS_LIST_PAGINATION
  } from "@core/store/mutation-constants";
  import { mapFields } from "@vasiliyrusin/vue-mapfields";
  import { mapActions, mapMutations, mapState } from "vuex";
  import FField from "@/components/Common/FField";
  import RequiredField from "@/components/Common/RequiredField";
  
  export default {
    name: "IntegrationsNewIntegrationModal",
    mixins: [Modal],
    components: {
      RequiredField,
      FField,
      ModalBox,
      FSelect,
      UserOutput,
      OfferOutput
    },
    computed: {
      ...mapState("admin/integration/edit", {
        offersList: state => state.offersList.data,
        offerAdvertisersList: state => state.offerAdvertisersList.data,
        crmList: state => state.CRMList.data
      }),
      
      ...mapFields("admin/integration/edit", {
        fields: ["advertiser", "offer", "crm"],
        base: "filters",
        action: UPDATE_INTEGRATION_FILTERS
      }),

      ...mapFields("admin/integration/edit", {
        fields: ["options"],
        base: "",
        action: UPDATE_INTEGRATION_OPTIONS
      }),

      isAbleToCreate () {
        return Boolean(this.offer && this.advertiser && this.crm);
      },

      isLoadingCrm () {
        return this.$wait(`admin/integration/edit/${ GET_CRM_OPTIONS }`);
      },

      isCrmSelected () {
        return this.$store.getters["admin/integration/edit/isCrmSelected"];
      },

      isLaunched: {
        get () {
          return this.$store.state.admin.integration.edit.isLaunched;
        },

        set (value) {
          this.SET_IS_LAUNCHED(value);
        }
      },

      integrationOldValue: {
        get () {
          return this.crm;
        },

        set (value) {
          if (value) this.crm = value;
        }
      }
    },
    methods: {
      ...mapMutations("admin/integration/edit", {
        OFFER_ADVERTISERS_SET_EMPTY: `offerAdvertisersList/${ SET_EMPTY }`,
        UPDATE_OFFER_ADVERTISERS_PAGINATION: `offerAdvertisersList/${ UPDATE_OFFER_ADVERTISERS_PAGINATION }`,
        UPDATE_OFFER_ADVERTISERS_FILTERS: `offerAdvertisersList/${ UPDATE_OFFER_ADVERTISERS_FILTERS }`,
        UPDATE_OFFERS_LIST_PAGINATION: `offersList/${ UPDATE_OFFERS_LIST_PAGINATION }`,
        UPDATE_OFFERS_FILTERS: `offersList/${ UPDATE_OFFERS_FILTERS }`,
        UPDATE_CRM_LIST_PAGINATION: `CRMList/${ UPDATE_CRM_LIST_PAGINATION }`,
        UPDATE_CRM_FILTERS: `CRMList/${ UPDATE_CRM_FILTERS }`,
        SET_OFFER_ID,
        SET_INTEGRATION_OPTIONS,
        SET_IS_LAUNCHED,
        SET_ADVERTISER_ID,
        SET_CRM_ALIAS
      }),

      ...mapActions("admin/integration/edit", {
        GET_OFFER_ADVERTISERS_LIST: `offerAdvertisersList/${ GET_OFFER_ADVERTISERS_LIST }`,
        GET_OFFERS_LIST: `offersList/${ GET_OFFERS_LIST }`,
        GET_CRM_LIST: `CRMList/${ GET_CRM_LIST }`,
        GET_INITIAL_OFFERS: `offersList/${ GET_INITIAL_OFFERS }`,
        CREATE_INTEGRATION,
        GET_CRM_OPTIONS,
        SET_EMPTY
      }),

      ...mapActions("admin/integrations", [
        GET_INTEGRATIONS
      ]),

      cancelIntegration () {
        this.isModalActive = false;
        this.SET_EMPTY();
        this.GET_INTEGRATIONS();
      },

      async addIntegration () {
        try {
          await this.CREATE_INTEGRATION();
          this.$buefy.toast.open({
            message: this.$t("admin.integrations.messages.errors.INTEGRATION_HAS_BEEN_CREATED"),
            type: "is-success"
          });
          if(this.isLaunched) {
            this.$buefy.toast.open({
              message: this.$t("admin.integrations.messages.errors.INTEGRATION_HAS_BEEN_LAUNCHED"),
              type: "is-success"
            });
          }
          this.emitUpdate();
          this.isModalActive = false;
          this.SET_EMPTY();
        } catch (e) {
          this.$buefy.toast.open({
            message: this.$t("admin.integrations.messages.errors.INTEGRATION_CANT_BE_LAUNCHED"),
            type: "is-danger"
          });
        }
      },

      async getCrmList (label = "") {
        this.UPDATE_CRM_LIST_PAGINATION();
        this.UPDATE_CRM_FILTERS({ name: label.length > 0 ? label : null });
        await this.GET_CRM_LIST();
        return this.crmList;
      },

      async getAdvertisers () {
        if (this.offer) {
          this.UPDATE_OFFER_ADVERTISERS_PAGINATION();
          await this.GET_OFFER_ADVERTISERS_LIST(this.offer);
          return this.offerAdvertisersList;
        }
        return { items: [], count: 0 };
      },

      async getOffers (label = "") {
        this.UPDATE_OFFERS_LIST_PAGINATION();
        this.UPDATE_OFFERS_FILTERS({ nameOrIntId: label.length > 0 ? label : null });
        if (this.offersList?.items?.length > 0) {
          await this.GET_OFFERS_LIST();
        } else {
          const res = await this.GET_INITIAL_OFFERS(this.$route.query.offer);
          this.offer = res?.length > 0 ? res[0] : null;
        }
        return this.offersList;
      }
    },
    watch: {
      isActive (value) {
        if (!value) {
          this.cancelIntegration();
        } else {
          this.SET_EMPTY();
        }
      },

      offer () {
        this.advertiser = null;
        this.OFFER_ADVERTISERS_SET_EMPTY();
      },

      crm (value) {
        if (value) {
          this.GET_CRM_OPTIONS(value);
        } else {
          this.SET_INTEGRATION_OPTIONS([]);
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
</style>
