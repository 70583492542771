<template>
    <div class="wrapper">
        <IntegrationsFilters></IntegrationsFilters>
        <IntegrationsTable></IntegrationsTable>
    </div>
</template>

<script>
  import { mapActions, mapMutations, mapState } from "vuex";
  import { mapFields } from "@vasiliyrusin/vue-mapfields";
  import { GET_INTEGRATIONS, SET_EMPTY } from "@core/store/action-constants";
  import { UPDATE_INTEGRATIONS_FILTERS, UPDATE_INTEGRATIONS_PAGINATION } from "@core/store/mutation-constants";
  import { DebounceUpdateByWatchedParams } from "@core/mixins";
  import IntegrationsTable from "@/components/Admin/Integrations/IntegrationsTable";
  import IntegrationsFilters from "@/components/Admin/Integrations/IntegrationsFilters";

  export default {
    name: "Integrations",

    mixins: [DebounceUpdateByWatchedParams],

    components: {
      IntegrationsFilters,
      IntegrationsTable
    },

    created () {
      const {
        crm,
        country,
        page,
        perPage
      } = this.$route.query;

      if (page) {
        this.page = Number(page);
      }

      if (perPage) {
        this.perPage = Number(perPage);
      }

      this.UPDATE_INTEGRATIONS_FILTERS({
        ...crm && { crm },
        ...country && { country }
      });
    },

    computed: {
      ...mapFields("admin/integrations", {
        fields: ["perPage", "page"],
        base: "pagination",
        action: UPDATE_INTEGRATIONS_PAGINATION
      }),

      ...mapState("admin/integrations", {
        updateParams: ({ pagination, filters }) => ({ pagination, filters })
      })
    },

    methods: {
      ...mapActions("admin/integrations", [
        GET_INTEGRATIONS,
        SET_EMPTY
      ]),

      ...mapMutations("admin/integrations", [
        UPDATE_INTEGRATIONS_FILTERS
      ]),

      async updated () {
        await this.GET_INTEGRATIONS();
    
        const query = {
          ...this.updateParams.filters,
          ...this.updateParams.pagination
        };

        this.$router.push({
          query
        }).catch(_ => {});
      }
    },

    destroyed () {
      this.SET_EMPTY();
    }
  };
</script>

<style lang="scss" scoped>
    @import "~bulma";
    @import "~buefy/src/scss/buefy";
    
    .integration {
        &-select {
            margin-bottom: 10px;
        }
    }
</style>
