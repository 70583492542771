<template>
    <div class="columns is-12 is-vcentered">
        <Permissions :permissions="['ADVERTISERS.LIST.ALL', 'ADVERTISERS.LIST.OWN', 'ADVERTISERS.LIST.UNBIND']">
            <div class="column is-2">
                <b-field :label="$t(`admin.integrations.filters.labels.advertiser`)">
                    <FSelect
                        v-model="advertiser"
                        :get-data-vuex="getAdvertisers"
                        :min-required-length="1"
                        :placeholder="$t(`admin.integrations.filters.labels.advertiser`)"
                        field="login">
                        <template #text="{ option }">
                            <UserOutput :user="option"></UserOutput>
                        </template>
                        <template #selected-option="{ option }">
                            <UserOutput :user="option"></UserOutput>
                        </template>
                    </FSelect>
                </b-field>
            </div>
        </Permissions>
        <div class="column is-2">
            <b-field :label="$t(`admin.integrations.filters.labels.offer`)">
                <FSelect
                    v-model="offer"
                    :get-data-vuex="getOffers"
                    :placeholder="$t(`admin.integrations.filters.labels.offer`)">
                    <template #text="{ option }">
                        <OfferOutput :offer="option"></OfferOutput>
                    </template>
                    <template #selected-option="{ option }">
                        <OfferOutput :offer="option"></OfferOutput>
                    </template>
                </FSelect>
            </b-field>
        </div>
        <div class="column is-2">
            <b-field :label="$t(`admin.integrations.filters.labels.CRM`)">
                <FSelect
                    v-model="crm"
                    :get-data-vuex="getCrmList"
                    :placeholder="$t(`admin.integrations.filters.labels.CRM`)"
                    field="name"
                    prop="alias">
                </FSelect>
            </b-field>
        </div>
        <div class="column is-2">
            <b-field :label="$t(`admin.integrations.filters.labels.country`)">
                <FCountrySelect
                    v-model="country"
                    multiple
                    :placeholder="$t(`webmaster.offers.filters.labels.country`)">
                </FCountrySelect>
            </b-field>
        </div>

        <Permissions :permissions="['INTEGRATIONS.CREATE']">
            <div class="column has-text-right">
                <b-field>
                    <template #label>
                        &#8203;
                    </template>
                    <b-button
                        type="is-success is-light"
                        @click="isAddIntegrationModal = true">
                        {{ $t(`admin.integrations.filters.values.integration`) }}
                    </b-button>
                </b-field>
            </div>
        </Permissions>

        <IntegrationsNewIntegrationModal
            :is-active.sync="isAddIntegrationModal"
            @update="emitUpdate">
        </IntegrationsNewIntegrationModal>
    </div>
</template>

<script>
  import IntegrationsNewIntegrationModal from "@/components/Admin/Integrations/IntegrationsNewIntegrationModal";
  import FCountrySelect from "@/components/Common/FCountrySelect";
  import OfferOutput from "@/components/Common/OfferOutput";
  import Permissions from "@/components/Common/Permissions";
  import FSelect from "@/components/Common/FSelect";
  import UserOutput from "@/components/Common/UserOutput";
  import { Filters, OffersFilters, UsersFilters } from "@core/mixins";
  import {
    GET_ADVERTISERS_LIST,
    GET_CRM_LIST,
    GET_INITIAL_ADVERTISERS,
    GET_INITIAL_CRM,
    GET_INITIAL_OFFERS,
    GET_OFFERS_LIST
  } from "@core/store/action-constants";
  import {
    UPDATE_ADVERTISERS_FILTERS,
    UPDATE_ADVERTISERS_LIST_PAGINATION,
    UPDATE_CRM_FILTERS,
    UPDATE_CRM_LIST_PAGINATION,
    UPDATE_INTEGRATIONS_FILTERS,
    UPDATE_OFFERS_FILTERS,
    UPDATE_OFFERS_LIST_PAGINATION
  } from "@core/store/mutation-constants";
  import { mapFields } from "@vasiliyrusin/vue-mapfields";
  import { mapActions, mapMutations, mapState } from "vuex";

  export default {
    name: "IntegrationsFilters",
    mixins: [Filters, UsersFilters, OffersFilters],
    components: {
      Permissions,
      FCountrySelect,
      UserOutput,
      IntegrationsNewIntegrationModal,
      FSelect,
      OfferOutput
    },

    data () {
      return {
        isAddIntegrationModal: false
      };
    },

    computed: {
      ...mapFields("admin/integrations", {
        fields: ["advertiser", "offer", "crm", "country"],
        base: "filters",
        action: UPDATE_INTEGRATIONS_FILTERS
      }),

      ...mapState("admin/integrations", {
        offersList: state => state.offersList.data,
        advertisersList: state => state.advertisersList.data,
        crmList: state => state.CRMList.data
      }),

      ...mapState("admin/integrations", {
        integrationsFilters: state => state.filters
      })
    },
    methods: {
      ...mapMutations("admin/integrations", {
        UPDATE_ADVERTISERS_LIST_PAGINATION: `advertisersList/${ UPDATE_ADVERTISERS_LIST_PAGINATION }`,
        UPDATE_ADVERTISERS_FILTERS: `advertisersList/${ UPDATE_ADVERTISERS_FILTERS }`,
        UPDATE_OFFERS_LIST_PAGINATION: `offersList/${ UPDATE_OFFERS_LIST_PAGINATION }`,
        UPDATE_OFFERS_FILTERS: `offersList/${ UPDATE_OFFERS_FILTERS }`,
        UPDATE_CRM_LIST_PAGINATION: `CRMList/${ UPDATE_CRM_LIST_PAGINATION }`,
        UPDATE_CRM_FILTERS: `CRMList/${ UPDATE_CRM_FILTERS }`
      }),

      ...mapActions("admin/integrations", {
        GET_ADVERTISERS_LIST: `advertisersList/${ GET_ADVERTISERS_LIST }`,
        GET_OFFERS_LIST: `offersList/${ GET_OFFERS_LIST }`,
        GET_CRM_LIST: `CRMList/${ GET_CRM_LIST }`,
        GET_INITIAL_CRM: `CRMList/${ GET_INITIAL_CRM }`,
        GET_INITIAL_ADVERTISERS: `advertisersList/${ GET_INITIAL_ADVERTISERS }`,
        GET_INITIAL_OFFERS: `offersList/${ GET_INITIAL_OFFERS }`
      }),

      async getAdvertisers (label = "") {
        this.UPDATE_ADVERTISERS_LIST_PAGINATION();
        this.UPDATE_ADVERTISERS_FILTERS({ loginOrIntId: label.length > 0 ? label : null });
        if (this.advertisersList?.items?.length > 0) {
          await this.GET_ADVERTISERS_LIST();
        } else {
          const res = await this.GET_INITIAL_ADVERTISERS(this.$route.query.advertiser);
          if (res?.length > 0) {
            this.advertiser = res[0];
          }
        }
        return this.advertisersList;
      },

      async getOffers (label = "") {
        this.UPDATE_OFFERS_LIST_PAGINATION();
        this.UPDATE_OFFERS_FILTERS({ nameOrIntId: label.length > 0 ? label : null });
        if (this.offersList?.items?.length > 0) {
          await this.GET_OFFERS_LIST();
        } else {
          const res = await this.GET_INITIAL_OFFERS(this.$route.query.offer);
          if (res?.length > 0) {
            this.offer = res[0];
          }
        }
        return this.offersList;
      },

      async getCrmList (label = "") {
        this.UPDATE_CRM_LIST_PAGINATION();
        this.UPDATE_CRM_FILTERS({ name: label.length > 0 ? label : null });
        // if (this.crmList?.items?.length > 0) {
        await this.GET_CRM_LIST();
        // }
        // else {
        //   const res = await this.GET_INITIAL_CRM(this.$route.query.crm);
        //   this.crm = res?.length > 0 ? res[0] : null;
        // }
        return this.crmList;
      }
    }
  };
</script>

<style scoped lang="scss">
    @import "~bulma";
    @import "~buefy/src/scss/buefy";
</style>
