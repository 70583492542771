<template>
    <div>
        <TablePlaceholder :data="integrations">
            <template slot-scope="{ items, count }">
                <FTable
                    :current-page="page"
                    :data="items"
                    :is-loading="isLoading"
                    :per-page.sync="perPage"
                    :total="count"
                    @update:currentPage="updateCurrentPage">
                    <el-table-column
                        v-slot="{ row }"
                        width="50px"
                        align="center">
                        <ColoredStatus :type="coloredStatusIntegration(row.isLaunched)"></ColoredStatus>
                    </el-table-column>

                    <el-table-column
                        v-slot="{ row }"
                        :label="$t(`admin.integrations.table.labels.offer`)">
                        <OfferOutput :offer="row.offer"></OfferOutput>
                    </el-table-column>

                    <el-table-column
                        v-slot="{ row }"
                        :label="$t(`admin.integrations.table.labels.advertiser`)">
                        <UserOutput :user="row.advertiser"></UserOutput>
                    </el-table-column>

                    <el-table-column
                        v-slot="{ row }"
                        :label="$t(`admin.integrations.table.labels.errors`)">
                        <ColoredStatus :type="coloredStatusErrors(row.lastCheckedAt, row.errors.length)">
                            <template v-if="row.lastCheckedAt">
                                <template v-if="row.errors.length === 0">
                                    {{ $t(`admin.integrations.table.values.errors.empty`) }}
                                </template>
                                <LTooltip
                                    v-else
                                    class="line_break_hack"
                                    position="is-bottom"
                                    type="is-dark">
                                    <template #content>
                                        <ul class="is-marginless">
                                            <li
                                                :key="item"
                                                v-for="item in row.errors">
                                                {{ item }}
                                            </li>
                                        </ul>
                                    </template>
                                    <span>
                                        {{ $t(`admin.integrations.table.values.errors.numberOfErrors`, { errorsCount: row.errors.length }) }}
                                    </span>
                                </LTooltip>
                            </template>
                            <template v-else>
                                {{ $t(`admin.integrations.table.values.errors.noData`) }}
                            </template>
                        </ColoredStatus>
                    </el-table-column>


                    <el-table-column
                        v-slot="{ row }"
                        :label="$t(`admin.integrations.table.labels.lastCheckedAt`)">
                        <template v-if="row.crm.alias === 'lg'">
                            <a @click="validateCrm(row)">
                                {{
                                    row.lastCheckedAt
                                        ? momentFrom(row.lastCheckedAt)
                                        : $t(`admin.integrations.table.values.lastCheckedAt.neverUpdated`)
                                }}
                            </a>
                        </template>
                        <template v-else>
                            {{
                                $t(`admin.integrations.table.values.lastCheckedAt.unsupportedCrm`,
                                   { crmName: row.crm.name })
                            }}
                        </template>
                    </el-table-column>

                    <el-table-column
                        v-slot="{ row }"
                        class-name="settings"
                        align="right">
                        <Permissions
                            v-if="!row.isLaunched"
                            :permissions="['INTEGRATIONS.LAUNCH']">
                            <LTooltip
                                :label="$t(`admin.integrations.table.dropdown.launch`)"
                                position="is-left"
                                class="mr-1"
                                type="is-success">
                                <b-button
                                    icon-left="play"
                                    rounded
                                    size="is-small"
                                    type="is-success is-light"
                                    @click="launchIntegration(row.id)">
                                </b-button>
                            </LTooltip>
                        </Permissions>

                        <Permissions
                            v-else
                            :permissions="['INTEGRATIONS.STOP']">
                            <LTooltip
                                :label="$t(`admin.integrations.table.dropdown.stop`)"
                                position="is-left"
                                type="is-danger">
                                <b-button
                                    icon-left="stop"
                                    rounded
                                    size="is-small"
                                    type="is-danger is-light"
                                    @click="stopIntegration(row.id)">
                                </b-button>
                            </LTooltip>
                        </Permissions>

                        <Permissions :permissions="['INTEGRATIONS.REMOVE']">
                            <DropdownOptions rounded>
                                <DropdownOption
                                    icon="trash-alt"
                                    type="is-danger is-light"
                                    @click.prevent="handleDelete(row)">
                                    {{ $t(`admin.integrations.table.dropdown.delete`) }}
                                </DropdownOption>
                            </DropdownOptions>
                        </Permissions>
                    </el-table-column>
                </FTable>
            </template>
        </TablePlaceholder>
    </div>
</template>

<script>
  import ColoredStatus from "@/components/Common/ColoredStatus";
  import DropdownOption from "@/components/Common/DropdownOptions/DropdownOption";
  import DropdownOptions from "@/components/Common/DropdownOptions/DropdownOptions";
  import OfferOutput from "@/components/Common/OfferOutput";
  import Permissions from "@/components/Common/Permissions";
  import UserOutput from "@/components/Common/UserOutput";
  import { momentFrom } from "@core/filters";
  import {
    DELETE_INTEGRATION,
    GET_INTEGRATIONS,
    LAUNCH_INTEGRATION,
    STOP_INTEGRATION,
    VALIDATE_CRM_LEAD_GROUP
  } from "@core/store/action-constants";
  import { UPDATE_INTEGRATIONS_PAGINATION } from "@core/store/mutation-constants";
  import { mapFields } from "@vasiliyrusin/vue-mapfields";
  import { mapActions, mapState } from "vuex";
  import TablePlaceholder from "@/components/Common/Table/TablePlaceholder";
  import FTable from "../../Common/FTable";

  export default {
    name: "IntegrationsTable",
    components: {
      Permissions,
      ColoredStatus,
      TablePlaceholder,
      DropdownOptions,
      DropdownOption,
      OfferOutput,
      UserOutput,
      FTable
    },

    computed: {
      ...mapState("admin/integrations", {
        integrations: state => state.integrations
      }),

      ...mapFields("admin/integrations", {
        fields: ["perPage", "page"],
        base: "pagination",
        action: UPDATE_INTEGRATIONS_PAGINATION
      }),

      isLoading () {
        return this.$wait(`admin/integrations/${ GET_INTEGRATIONS }`);
      },

      isPaginated () {
        return this.integrations.count > this.perPage;
      }
    },
    methods: {
      ...mapActions("admin/integrations", [
        GET_INTEGRATIONS,
        DELETE_INTEGRATION,
        VALIDATE_CRM_LEAD_GROUP,
        LAUNCH_INTEGRATION,
        STOP_INTEGRATION
      ]),

      emitUpdate () {
        this.GET_INTEGRATIONS();
      },

      updateCurrentPage (page) {
        this.page = page;
      },

      momentFrom (date) {
        return momentFrom(date);
      },

      coloredStatusIntegration (status) {
        return status ? "is-success" : "is-danger";
      },

      coloredStatusErrors (lastCheckedAt, errors) {
        if (lastCheckedAt) {
          return errors > 0 ? "is-danger" : "is-success";
        }
        return "is-warning";
      },

      async validateCrm (crm) {
        await this.VALIDATE_CRM_LEAD_GROUP(crm.id);
        this.emitUpdate();
      },

      handleDelete (row) {
        this.$buefy.dialog.confirm({
          title: this.$t("admin.integrations.modal.header.deleteIntegrationTitle"),
          message: this.$t("admin.integrations.modal.body.deleteMessage"),
          confirmText: this.$t("admin.integrations.modal.footer.delete"),
          cancelText: this.$t("admin.integrations.modal.footer.cancel"),
          type: "is-danger is-light",
          onConfirm: async () => {
            await this.DELETE_INTEGRATION(row.id);
            this.emitUpdate();
          }
        });
      },

      async launchIntegration (integrationId) {
        try {
          await this.LAUNCH_INTEGRATION(integrationId);
          this.$buefy.toast.open({
            message: this.$t("admin.integrations.messages.success.launch"),
            type: "is-success"
          });
          this.emitUpdate();
        } catch (e) {
          const errors = e.errors;
          if (errors && Array.isArray(errors)) {

            const messages = errors.map(e => e.message);

            this.$buefy.toast.open({
              message: this.$t(`admin.integrations.messages.errors.${ messages[0] }`),
              type: "is-danger"
            });
          }
        }

      },

      stopIntegration (integrationId) {
        this.$buefy.dialog.confirm({
          title: this.$t("admin.integrations.modal.header.stopIntegrationTitle"),
          message: this.$t("admin.integrations.modal.body.stopMessage"),
          confirmText: this.$t("admin.integrations.modal.footer.stopText"),
          cancelText: this.$t("admin.integrations.modal.footer.cancel"),
          type: "is-danger is-light",
          onConfirm: async () => {
            try {
              await this.STOP_INTEGRATION(integrationId);
              this.$buefy.toast.open({
                message: this.$t("admin.integrations.messages.success.stop"),
                type: "is-success"
              });
              this.emitUpdate();
            } catch (e) {
              this.$buefy.toast.open({
                message: this.$t("admin.integrations.messages.errors.fail"),
                type: "is-danger"
              });
            }
          }
        });
      }
    }
  };
</script>

<style lang="scss" scoped>
    @import "~bulma";
    @import "~buefy/src/scss/buefy";
    
    .line_break_hack.b-tooltip:after {
        white-space: pre;
    }

    ::v-deep {
        .settings {
            .cell {
                @apply flex justify-end items-center;
            }
        }

      ul {
        list-style: none;
      }
    }
</style>
