<template functional>
    <span v-if="props.offer">
        {{ $options.methods.formatEmptyString(props.offer.name) }}
        [{{ $options.methods.formatEmptyString(props.offer.intId) }}]
        <template v-if="props.offer.offerType && props.isIconEnabled && $options.iconType(props.offer.offerType)">
            <LTooltip
                type="is-dark"
                position="is-top"
                :label="parent.$t(`common.entity.offer.offerTypes.${ props.offer.offerType }`)">
                <b-icon
                    :icon="$options.iconType(props.offer.offerType)"
                    pack="fas"
                    size="is-small"></b-icon>
            </LTooltip>
        </template>
    </span>
    <span v-else>
        {{ $options.methods.formatEmptyString(null) }}
    </span>
</template>

<script>
  import { formatEmptyString } from "@core/filters";
  export default {
    name: "OfferOutput",
    props: {
      offer: {
        type: Object,
        default: null
      },
      isIconEnabled: {
        type: Boolean,
        default: false
      }
    },
    iconType (offerType) {
      const types = {
        "PUBLIC": "globe",
        "PRIVATE": "lock",
        "HIDDEN": "user-lock"
      };

      return types[offerType];
    },
    methods: {
      formatEmptyString
    }
  };
</script>

<style scoped>

</style>
